band:
  name: Bára Zmeková
  subtitle: 'Expo 2025'
  engineer:
    name: Dan Kadera
    phone: +420 773 111 988
    email: me@subsonic.cz
    note: (not coming to the Expo, but available for consultation)
options:
  - type: section
    label: 'Lineup:'
    items:
      - id: lineup
        type: checklist
        options:
          - { label: Mayu / accordion, value: mayu, default: true }
          - { label: Kimiya / violin, value: kimiya, default: true }
  - type: section
    label: Options
    items:
      - id: piano
        type: checkbox
        label: piano
        default: true
stage:
  areas:
    - id: left-arc
      type: arc
      x1: 0.075
      y1: 0.8
      $...:
          x2: 0.175
          y2: 0.425
          r: 0.6
    - id: back-line
      type: line
      x1: { $if: piano, $then: 0.475, $else: 0.45 }
      y1: 0.25
      x2: { $if: piano, $then: 0.9, $else: 0.7 }
      y2: 0.25
    - id: full-arc
      type: arc
      x1: 0.075
      y1: 0.85
      x2: 0.85
      y2: 0.225
      r: 0.65
  scale: { $if: piano, $then: 0.7, $else: 0.8 }
musicians:
  - id: vik
    name: Viktor
    instruments: drum kit
    sockets: 2
    addon:
      name: spd-sx
      position:
        right: 10px
        top: 10px
    placement:
      $if: piano
      $then:
        x: 0.85
        y: 0.55
        a: 90
      $else:
        x: 0.85
        y: 0.65
        a: 80
    minWidth: 200
    minHeight: 170
    inputs:
      - group: drums
        name: kick
      - group: drums
        name: snare 1
      - group: drums
        name: snare 2
      - group: drums
        name: hi-hat
      - group: drums
        name: hi tom
      - group: drums
        name: floor tom
      - group: drums
        name: overheads
        count: 2
      - group: drums
        name: spd-sx
        note: 2x DI
        count: 2
    monitors:
      - iem: true
        group: vik
        stereo: true
        note: 2x XLR in

  - $if: { lineup: { $includes: 'kimiya' } }
    id: kimiya
    name: Kimiya
    instruments: violin
    mic: true
    placement: { $if: piano, $then: left-arc, $else: full-arc }
    inputs:
      - group: band
        name: violin
      - group: backvocals
        name: vocal Kimiya
    monitors:
      - group: mono
        note: floor monitor
        placement:
          $if: piano
          $then: { x: 0, y: 0.3, a: 0 }
          $else: { x: -0.05, y: 0.3 }

  - $if: { lineup: { $includes: 'mayu' } }
    id: mayu
    name: Mayu
    instruments: accordion
    mic: true
    placement: { $if: piano, $then: back-line, $else: full-arc }
    inputs:
      - group: band
        name: accordion
      - group: backvocals
        name: vocal Mayu
    monitors:
      - group: mono
        note: floor monitor
        placement:
          $if: piano
          $then: { x: 0, y: 0.3, a: 0 }
          $else: { x: -0.05, y: 0.3 }

  - id: pavel
    name: Pavel
    instruments: bass
    mic: true
    sockets: 2
    placement: { $if: piano, $then: back-line, $else: full-arc }
    inputs:
      - group: bass
        name: bass
        note: 1x DI
      - group: backvocals
        name: vocal Pavel
        note: own SM58
    monitors:
      - iem: true
        group: pavel
        stereo: true
        note: 1x XLR in with Y-split at stagebox

  - $if: piano
    id: piano
    name: Bára
    instruments: piano
    mic:
      left: 45%
      top: 100%
      transform: rotate(180deg) translate(-50%, 30%)
    placement: { x: 0.475, y: 0.55, a: 90 }
    minWidth: 140
    minHeight: 170
    inputs:
      - group: keys
        name: piano
        count: 2
    monitors:
      - $if: piano
        iem: true
        group: bara
        stereo: true
        note: wireless; 2x XLR or TRS in

  - id: keys
    name: { $if: piano, $then: '', $else: Bára }
    instruments: keys
    mic:
      left: '50%'
      top: '-1%'
    sockets: 3
    placement:
      $if: piano
      $then:
        x: 0.35
        y: 0.75
      $else:
        x: 0.5
        y: 0.9
    minWidth: 120
    minHeight: 60
    inputs:
      - group: keys
        name: synth
        note: 2x DI
        count: 2
      - group: vocals
        name: { $if: piano, $then: vocal Bára piano, $else: vocal Bára }
        note: own KMS 104 Plus
      - $if: piano
        group: vocals
        name: vocal Bára keys
        note: own OM5
    monitors:
      - iem: true
        group: bara
        stereo: true
        note: wireless; 2x XLR or TRS in
        $if: { piano: false }

inputList:
  groups:
    - drums
    - bass
    - keys
    - band
    - backvocals
    - vocals

monitorList:
  groups:
    - bara
    - vik
    - pavel
    - mono
  note: >-
    Most of the band uses in-ear monitors. Musicians bring their own
    IEM devices; wired devices will each require 1-2 additional
    XLR cables of adequate length from the stagebox.
mainOutputs: []
notes: []
