import { z } from 'zod';

const engineer = z.object({
  name: z.string(),
  phone: z.string(),
  email: z.string().email(),
  note: z.string().optional(),
}).strict();

export type Engineer = z.infer<typeof engineer>;

const band = z.object({
  name: z.string(),
  subtitle: z.string().optional(),
  engineer: engineer.optional(),
}).strict();

export type Band = z.infer<typeof band>;

const checkboxOptionDefinition = z.object({
  id: z.string(),
  type: z.literal('checkbox'),
  label: z.string(),
  default: z.boolean().optional(),
}).strict();

export type CheckboxOptionDefinition = z.infer<typeof checkboxOptionDefinition>;

const checklistValueDefinition = z.object({
  label: z.string(),
  value: z.string(),
  default: z.boolean().optional(),
  exclusive: z.string().optional(),
}).strict();

export type ChecklistValueDefinition = z.infer<typeof checklistValueDefinition>;

const checklistOptionDefinition = z.object({
  id: z.string(),
  type: z.literal('checklist'),
  options: z.array(checklistValueDefinition),
}).strict();

export type ChecklistOptionDefinition = z.infer<typeof checklistOptionDefinition>;

export type OptionsSectionDefinition = {
  type: 'section';
  label: string;
  items: OptionDefinition[];
};

export type OptionDefinition = CheckboxOptionDefinition | ChecklistOptionDefinition | OptionsSectionDefinition;

const optionsSectionDefinition: z.ZodSchema<OptionsSectionDefinition> = z.object({
  type: z.literal('section'),
  label: z.string(),
  items: z.lazy(() => z.array(optionDefinition)),
}).strict() as any;

const optionDefinition: z.ZodSchema<OptionDefinition> = z.union([
  checkboxOptionDefinition,
  checklistOptionDefinition,
  optionsSectionDefinition,
]);

const options = z.array(optionDefinition);

export type Options = z.infer<typeof options>;

const lineArea = z.object({
  id: z.string(),
  type: z.literal('line'),
  x1: z.number(),
  y1: z.number(),
  x2: z.number(),
  y2: z.number(),
}).strict();

export type LineArea = z.infer<typeof lineArea>;

const arcArea = z.object({
  id: z.string(),
  type: z.literal('arc'),
  x1: z.number(),
  y1: z.number(),
  x2: z.number(),
  y2: z.number(),
  r: z.number(),
}).strict();

export type ArcArea = z.infer<typeof arcArea>;

export type StageArea = LineArea | ArcArea;

const stage = z.object({
  areas: z.array(z.union([lineArea, arcArea])),
  scale: z.number().optional(),
}).strict();

export type Stage = z.infer<typeof stage>;

const placement = z.object({
  x: z.number(),
  y: z.number(),
  a: z.number().optional(),
}).strict();

export type Placement = z.infer<typeof placement>;

const musicianInput = z.object({
  group: z.string(),
  name: z.string(),
  note: z.string().optional(),
  count: z.number().optional(),
  align: z.boolean().optional(),
  visible: z.boolean().optional(),
}).strict();

export type MusicianInput = z.infer<typeof musicianInput>;

const musicianMonitor = z.object({
  group: z.string(),
  stereo: z.boolean().optional(),
  iem: z.boolean().optional(),
  note: z.string().optional(),
  placement: placement.optional(),
  visible: z.boolean().optional(),
}).strict();

export type MusicianMonitor = z.infer<typeof musicianMonitor>;

const musicianAddon = z.object({
  name: z.string(),
  position: z.any(),
}).strict();

export type MusicianAddon = z.infer<typeof musicianAddon>;

const musicianOptions = z.object({
  id: z.string(),
  name: z.string().optional(),
  instruments: z.string().optional(),
  mic: z.union([z.boolean(), z.any()]).optional(),
  sockets: z.number().optional(),
  placement: z.union([z.string(), placement]).optional(),
  minWidth: z.number().optional(),
  minHeight: z.number().optional(),
  addon: musicianAddon.optional(),
  inputs: z.array(musicianInput).optional(),
  monitors: z.array(musicianMonitor).optional(),
  visible: z.boolean().default(true),
}).strict();

export type MusicianOptions = z.infer<typeof musicianOptions>;

const listOptions = z.object({
  groups: z.array(z.string()).optional(),
  note: z.string().optional(),
}).strict();

export type ListOptions = z.infer<typeof listOptions>;

const mainOutput = z.object({
  name: z.string(),
  stereo: z.boolean().optional(),
  note: z.string().optional(),
}).strict();

export type MainOutput = z.infer<typeof mainOutput>;

const backlineItem = z.object({
  name: z.string(),
  musician: z.string(),
  note: z.string().optional(),
}).strict();

export type BacklineItem = z.infer<typeof backlineItem>;

export const schema = z.object({
  band,
  options,
  stage: stage.optional(),
  musicians: z.array(musicianOptions),
  inputList: listOptions.optional(),
  monitorList: listOptions.optional(),
  mainOutputs: z.array(mainOutput).optional(),
  notes: z.array(z.string()).optional(),
  backline: z.array(backlineItem).optional(),
}).strict();

export type Schema = z.infer<typeof schema>;
