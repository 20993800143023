import React, { useEffect } from 'react';
import classNames from 'classnames';
import { useData } from './data';

import * as css from './header.module.less';

const y = new Date().getFullYear();

export type HeaderProps = {
  printOnly?: boolean;
};

export const Header: React.FC<HeaderProps> = ({ printOnly }) => {
  const { band } = useData();
  const subtitle = band.subtitle ?? `tech rider ${y}`;

  useEffect(() => {
    document.title = `${band.name} - ${subtitle}`;
  }, [subtitle]);

  return (
    <header className={classNames(css.container, printOnly && css['print-only'])}>
      <h1 className={css.title}>{band.name}</h1>
      <h2 className={css.subtitle}>{subtitle}</h2>
    </header>
  );
};
