import React from 'react';
import { useData } from './data';

import * as headerStyle from './header.module.less';
import * as notesStyle from './notes.module.less';

export const Notes: React.FC = () => {
  const { band: { engineer }, backline, notes } = useData();

  return (
    <>
      {backline && backline.length ? (
        <>
          <h3 className={headerStyle.section}>Backline</h3>
          <ul className={notesStyle.list}>
            {backline.map((item, i) => (
              <li key={i}><strong>{item.name}</strong> ({item.musician}){item.note !== undefined ? ` – ${item.note}` : ''}</li>
            ))}
          </ul>
        </>
      ) : undefined}
      <h3 className={headerStyle.section}>General info</h3>
      {notes && notes.length ? (
        <ul className={notesStyle.list}>
          {notes.map((note, i) => <li key={i}>{note}</li>)}
        </ul>
      ) : undefined}
      {engineer ? (
        <>
          <h3 className={headerStyle.section}>Band engineer</h3>
          <p>
            <strong>{engineer.name}</strong><br />
            <a href={`mailto:${engineer.email}`}>{engineer.email}</a><br />
            <a href={`tel:${engineer.phone.replace(/[-\s.()]+/g, '')}`}>{engineer.phone}</a>
            {engineer.note ? (<><br />{engineer.note}</>) : undefined}
          </p>
        </>
      ) : undefined}
    </>
  );
};
